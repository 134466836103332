import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullScreenLayoutComponent} from '../../infrastructure/layout/full-screen-layout/full-screen-layout.component';


const routes: Routes = [
    {
        path: 'guest',
        component: FullScreenLayoutComponent,
        children: [
            {
                path: 'firmar-solicitud',
                loadChildren: () => import('./modules/firmar-solicitud/firmar-solicitud.module').then(m => m.FirmarSolicitudModule),
            },
            {
                path: 'error',
                loadChildren: () => import('./modules/pagina-error/pagina-error.module').then(m => m.PaginaErrorModule),
            },
            {
                path: 'success',
                loadChildren: () => import('./modules/pagina-ok/pagina-ok.module').then(m => m.PaginaOkModule),
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class GuestRoutingModule {
}
