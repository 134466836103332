import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-full-screen-layout',
  templateUrl: './full-screen-layout.component.html',
  styleUrls: ['./full-screen-layout.component.scss']
})
export class FullScreenLayoutComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
