import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FullScreenLayoutComponent} from './infrastructure/layout/full-screen-layout/full-screen-layout.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {EnvUrlModule, ValidationService} from 'firma-base';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderLayoutComponent} from './infrastructure/layout/header-layout/header-layout.component';
import {HeaderComponent} from './infrastructure/layout/header-layout/header/header.component';
import {UserInfoComponent} from './infrastructure/layout/header-layout/header/user-info/user-info.component';
import {MenuComponent} from './infrastructure/layout/header-layout/menu/menu.component';
import {DatePipe} from '@angular/common';
import {GuestRoutingModule} from './domain/guest/guest-routing.module';
import {AngularxAuthModule, JwtInterceptorService} from '@lunia-consultores/angularx-auth';
import {UsuarioRoutingModule} from './domain/usuario/usuario-routing.module';
import {environment} from '../environments/environment';
import {PublicRoutingModule} from './domain/public/public-routing.module';
import {AuditoriaRoutingModule} from './domain/auditoria/auditoria-routing.module';
import {ImagenMarcaModule} from './application/shared/modules/imagen-marca/imagen-marca.module';
import {MsalInterceptor, MsalModule, MsalService} from '@azure/msal-angular';
import {CustomJwtInterceptorService} from './infrastructure/shared/authentication/custom-jwt-interceptor.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.apiUrl + '/public/langs/', '');
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderLayoutComponent,
        MenuComponent,
        HeaderComponent,
        UserInfoComponent,
        FullScreenLayoutComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GuestRoutingModule,
        AuditoriaRoutingModule,
        ImagenMarcaModule.forRoot(),
        MsalModule,
        AngularxAuthModule.forRoot({
            backendUrl: environment.authUrl,
            unauthorizedDefaultRoute: 'auth/login',
            jwtLocalStorageName: 'token',
            useSessionStorage: true,
            jwtSessionStorageName: 'token'
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToastrModule.forRoot(),
        EnvUrlModule.forRoot(
            environment.apiUrl,
            environment.authUrl,
            environment.guestUrl,
            environment.baseUrl,
            environment.auditoriaUrl
        ),
        UsuarioRoutingModule,
        PublicRoutingModule,
    ],
    providers: [
        ValidationService,
        DatePipe,
        MsalService,
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: CustomJwtInterceptorService,
                multi: true
            },

        ]
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
