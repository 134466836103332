import {Component, OnInit} from '@angular/core';
import {MenuItem} from './menu-item.model';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public currentRoute = '';
  public menuItems = [] as MenuItem[];
  public isShown = false;
  public form: FormGroup;
  public menuVisible = false;
  public menuItem: MenuItem;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
    });
  }

  public ngOnInit(): void {
    this.getMenu();
  }

  private getMenu(): void {
    this.menuItems.push({
      id: 1,
      title: 'Solicitudes',
      iconClass: 'fas fa-file-signature',
      description: 'Solicitudes de firma',
      url: '/solicitud-firma',
      class: '',
      childItems: [],
      lastItem: false
    });
  }

  public toggle(): void {
    this.isShown = !this.isShown;
  }

  ocultarMenu() {
    this.menuVisible = false;
  }

  public mostrarMenu(menuItem: MenuItem) {
    if (menuItem.childItems.length > 0) {
      this.menuItem = menuItem;
      this.menuVisible = true;
    } else {
      this.router.navigate([menuItem.url]);
      this.menuVisible = false;
    }
  }

  public isActivo(menuItem: MenuItem) {
    let activo = false;
    if (menuItem.childItems.length === 0 && this.currentRoute.search(menuItem.url) !== -1) {
      activo = true;
    } else {
      menuItem.childItems.forEach(child => {
        if (this.currentRoute.search(child.url) !== -1) {
          activo = true;
        }
      });
    }
    return activo;

  }

  public navegar(url: string) {
    this.menuVisible = false;
    this.router.navigate([url]);
  }

  public showElementoMenu(menuItem: MenuItem): boolean {
    return true;
  }

  private navigateFirstItem(): void {
    if (this.router.url === '/') {
      this.router.navigate([this.menuItems[0].url]);
    }
  }
}
