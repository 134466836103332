import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HeaderLayoutComponent} from '../../infrastructure/layout/header-layout/header-layout.component';
import {CompruebaUsuarioAutenticadoGuard} from '../../infrastructure/Guards/comprueba-usuario-autenticado-guard.service';

const routes: Routes = [
    {
        path: 'solicitud-firma',
        component: HeaderLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [CompruebaUsuarioAutenticadoGuard],
                loadChildren: () => import('../usuario/modules/solicitud-firma/solicitud-firma.module')
                    .then(m => m.SolicitudFirmaModule)
            },
            {
                path: 'datos-solicitud/:id',
                canActivate: [CompruebaUsuarioAutenticadoGuard],
                loadChildren: () => import('../usuario/modules/datos-solicitud/datos-solicitud.module')
                    .then(m => m.DatosSolicitudModule),
            },
            {
                path: 'crear-solicitud-firma',
                canActivate: [CompruebaUsuarioAutenticadoGuard],
                loadChildren: () => import('../usuario/modules/crear-solicitud-firma/crear-solicitud-firma.module')
                    .then(m => m.CrearSolicitudFirmaModule)
            },
            {
                path: 'documento-crear-solicitud-firma',
                canActivate: [CompruebaUsuarioAutenticadoGuard],
                loadChildren: () => import('../usuario/modules/crear-solicitud-firma-documento/crear-solicitud-firma-documento.module')
                    .then(m => m.CrearSolicitudFirmaDocumentoModule)
            },
            {
                path: 'vista-previa/:solicitudFirmaId',
                canActivate: [CompruebaUsuarioAutenticadoGuard],
                loadChildren: () => import('../usuario/modules/vista-previa-documento-firmado/vista-previa-documento-firmado.module')
                    .then(m => m.VistaPreviaDocumentoFirmadoModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class UsuarioRoutingModule {
}
