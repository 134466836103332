import {enableProdMode, Inject, InjectionToken} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {LogLevel} from 'msal';
import {MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration} from '@azure/msal-angular';

if (environment.production) {
    enableProdMode();
}

export const CONFIGURACION_AUTENTICACION = new InjectionToken<string>('CONFIGURACION_AUTENTICACION');

fetch(environment.baseUrl + 'publicas/configuracion-autenticacion').then(async (response) => {
    const respuesta = await response.json();
    let providers = [
        {
            provide: CONFIGURACION_AUTENTICACION,
            useValue: respuesta.configuracion_autenticacion
        },
        {
            provide: MSAL_CONFIG,
            useValue: {
                auth: {
                    clientId: null,
                    authority: null,
                    validateAuthority: false,
                    redirectUri: null,
                    postLogoutRedirectUri: null,
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: false,
                },
                system: {
                    loggerOptions: {
                        logLevel: LogLevel.Info,
                        piiLoggingEnabled: true,
                    },
                },
            }
        },
        {
            provide: MSAL_CONFIG_ANGULAR,
            useValue: {
                popUp: false,
                consentScopes: [],
                unprotectedResources: [],
                protectedResourceMap: [],
                extraQueryParameters: {},
            } as MsalAngularConfiguration
        }
    ];
    if (respuesta.configuracion_autenticacion !== 'auth-microservice') {
        const config = {
            auth: {
                clientId: respuesta.msal.clientId,
                authority: respuesta.msal.authority,
                validateAuthority: true,
                redirectUri: respuesta.msal.redirectUri,
                postLogoutRedirectUri: respuesta.msal.postLogoutRedirectUri,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: true,
                },
            },
        };

        const scopes = respuesta.graph.scopes;

        const protectedResourceMap = [];
        respuesta.interceptor.forEach((element: any) => {
            protectedResourceMap.push(element);
        });

        const configAngular = {
            popUp: false,
            consentScopes: scopes,
            unprotectedResources: [],
            protectedResourceMap,
            extraQueryParameters: {},
        } as MsalAngularConfiguration;

        providers = [
            {provide: CONFIGURACION_AUTENTICACION, useValue: respuesta.configuracion_autenticacion},
            {provide: MSAL_CONFIG, useValue: config},
            {provide: MSAL_CONFIG_ANGULAR, useValue: configAngular}
        ];
    }

    platformBrowserDynamic(providers)
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
});


