export const environment = {
    production: true,
    apiUrl: '/api/api/',
    authUrl: '/api-auth',
    printUrl: '',
    mediaUrl: '',
    guestUrl: '/api/guest/',
    auditoriaUrl: '/api/auditoria/',
    baseUrl: '/api/'
};
