import {Inject, Injectable} from '@angular/core';
import {ImagenMarca} from './imagen-marca';

@Injectable({
    providedIn: 'root'
})
export class ObtenerImagenMarcaService {

    private imagenMarcaUrl = '';
    public imagenMarca!: ImagenMarca;

    constructor(@Inject('baseUrl') private baseUrl: string) {
        this.imagenMarcaUrl = baseUrl + 'publicas/imagen-marca';
    }

    public async __invoke(): Promise<ImagenMarca> {
        try {
            const response = await fetch(this.imagenMarcaUrl);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const data: ImagenMarca = await response.json();
            this.imagenMarca = data;
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    public obtenerValor(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0])) {
            return undefined;
        }

        if (!Array.isArray(key)) {
            key = key.split('.');
        }

        return key.reduce((acc: any, current: string) => acc && acc[current], this.imagenMarca);
    }
}
