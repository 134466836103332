import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FullScreenLayoutComponent} from '../../infrastructure/layout/full-screen-layout/full-screen-layout.component';

const routes: Routes = [
    {
        path: 'auditoria',
        component: FullScreenLayoutComponent,
        children: [
            {
                path: 'log',
                loadChildren: () => import('./modules/log/log.module').then(m => m.LogModule),
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AuditoriaRoutingModule {
}
