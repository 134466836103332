import {RouterModule, Routes} from '@angular/router';
import {FullScreenLayoutComponent} from '../../infrastructure/layout/full-screen-layout/full-screen-layout.component';
import {NgModule} from '@angular/core';

const routes: Routes = [
    {
        path: 'auth',
        component: FullScreenLayoutComponent,
        children: [
            {
                path: 'login',
                loadChildren: () =>
                    import('./modules/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'restablecer-credenciales/:email/:token',
                loadChildren: () => import('./modules/restablecer-credenciales/restablecer-credenciales.module')
                    .then(m => m.RestablecerCredencialesModule)
            },
            {
                path: 'solicitud-restablecer-credenciales',
                loadChildren: () =>
                    import('./modules/solicitud-restablecer-credenciales/solicitud-restablecer-credenciales.module')
                        .then(m => m.SolicitudRestablecerCredencialesModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class PublicRoutingModule {
}
