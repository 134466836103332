import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ObtenerImagenMarcaService} from './obtener-imagen-marca.service';

export function inicializarImagenMarcaFactory(env: ObtenerImagenMarcaService): any {
    return async () => await env.__invoke();
}

@NgModule({
    declarations: [],
    imports: []
})


export class ImagenMarcaModule {
    static forRoot() {
        return {
            ngModule: ImagenMarcaModule,
            providers: [
                ObtenerImagenMarcaService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: inicializarImagenMarcaFactory,
                    deps: [ObtenerImagenMarcaService],
                    multi: true,
                }
            ]
        };
    }
}
