import {Component, ElementRef, HostListener, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LogoutStorageService} from '../../../../shared/authentication/logout-storage.service';
import {LoggedUser} from 'firma-base';
import {CONFIGURACION_AUTENTICACION} from '../../../../../../main';
import {MsalService} from '@azure/msal-angular';
import {Account} from 'msal';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.sass']
})
export class UserInfoComponent implements OnInit {
    public datosUsuario = {} as LoggedUser;
    public hideUserInfo = true;
    public configuracionAutenticacion: string;
    public usuarioMsal: Account = {} as Account;

    constructor(private elementRef: ElementRef,
                private router: Router,
                private logoutStorageService: LogoutStorageService,
                private injector: Injector,
                private msalService: MsalService) {
        this.configuracionAutenticacion = this.injector.get(CONFIGURACION_AUTENTICACION);
    }

    public ngOnInit(): void {
        if (this.configuracionAutenticacion === 'azure') {
            this.usuarioMsal = this.msalService.getAccount();
        } else {
            this.datosUsuario = (JSON.parse(localStorage.getItem('user')) as LoggedUser);
        }
    }

    async cerrarSesion() {
        if (this.configuracionAutenticacion === 'azure') {
            await this.msalService.logout();
        } else {
            this.logoutStorageService.__invoke();
            await this.router.navigate(['/auth/login']);
        }
    }

    public mostrarInformacionUsuario(): void {
        this.hideUserInfo = !this.hideUserInfo;
    }
}
