import {Injectable, Injector} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MsalService} from '@azure/msal-angular';
import {CONFIGURACION_AUTENTICACION} from '../../../main';

@Injectable({
    providedIn: 'root'
})
export class CompruebaUsuarioAutenticadoGuard implements CanActivate {
    private readonly configuracionAutenticacion: string;

    constructor(private injector: Injector,
                private msalService: MsalService,
                private router: Router) {
        this.configuracionAutenticacion = this.injector.get(CONFIGURACION_AUTENTICACION);
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.configuracionAutenticacion === 'azure') {
            const loggedIn = !!this.msalService.getAccount();
            if (!loggedIn) {
                this.router.navigate(['/auth/login']).then();
            }
            return loggedIn;
        } else {
            const loggedIn = !!localStorage.getItem('user');
            if (!loggedIn) {
                this.router.navigate(['/auth/login']).then();
            }
            return loggedIn;
        }
    }
}
