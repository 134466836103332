import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoutStorageService {
  constructor() { }
  public __invoke(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('user');
  }
}
