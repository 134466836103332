import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})

export class HeaderLayoutComponent implements OnInit {

  constructor(private meta: Meta) {

  }

  public ngOnInit(): void {
    this.meta.addTag({name: 'viewport', content: 'width=1024'});
  }


}
