import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import {AngularxAuthModuleConfig} from '@lunia-consultores/angularx-auth/lib/angularx-auth-module.config';
import {RefreshTokenService} from '@lunia-consultores/angularx-auth';

@Injectable({
  providedIn: 'root'
})
export class CustomJwtInterceptorService implements HttpInterceptor {
  private isRefreshingToken = false;
  public tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private inj: Injector,
              private router: Router,
              @Inject('config') private config: AngularxAuthModuleConfig) {
    console.log(this.config);
  }

  private addToken(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token && token.trim() !== '') {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }
    return req;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    // Si la solicitud ya tiene un token Bearer, no hacer nada
    const authHeader = req.headers.get('Authorization');
    if (authHeader && authHeader.startsWith('Bearer ')) {
      return next.handle(req);
    }

    let token = '';
    if (this.config.useSessionStorage) {
      if (sessionStorage.getItem(this.config.jwtSessionStorageName) !== null) {
        token = sessionStorage.getItem(this.config.jwtSessionStorageName) as string;
      } else {
        token = localStorage.getItem(this.config.jwtLocalStorageName) as string;
      }
    } else {
      token = localStorage.getItem(this.config.jwtLocalStorageName) as string;
    }

    return next.handle(this.addToken(req, token))
        .pipe(catchError(error => {
          console.log(error);
          if (error instanceof HttpErrorResponse) {
            switch ((error as HttpErrorResponse).status) {
              case 400:
                return this.handle400Error(error);
              case 401:
                return this.handle401Error(req, next);
              default:
                return throwError(error);
            }
          } else {
            return throwError(error);
          }
        }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>> {
    const authService = this.inj.get(RefreshTokenService);

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next('');

      return authService.refresh()
          .pipe(switchMap((newToken) => {
            if (newToken) {
              if (this.config.useSessionStorage && sessionStorage.getItem('token') !== null) {
                sessionStorage.setItem('token', newToken.token);
              } else {
                localStorage.setItem('token', newToken.token);
              }
              this.tokenSubject.next(newToken.token);
              return next.handle(this.addToken(req, newToken.token));
            }

            return this.logoutUser();
          }))
          .pipe(catchError(error => {
            return this.logoutUser();
          }))
          .pipe(finalize(() => {
            this.isRefreshingToken = false;
          }));

    } else {
      return this.tokenSubject
          .pipe(filter(token => token != null))
          .pipe(take(1))
          .pipe(switchMap(token => {
            return next.handle(this.addToken(req, token));
          }));
    }
  }

  private handle400Error(error: any): Observable<never> {
    switch (error.error.error) {
      case 'token_fully_expired':
        break;
      case 'token_invalid':
        this.goToLogin();
        break;

      case 'token_blacklisted':
        this.goToLogin();
        break;

      case 'token_not_provided':
        this.goToLogin();
        break;
    }
    return throwError(error);
  }

  public logoutUser(): Observable<never> {
    return throwError('');
  }

  private goToLogin(): void {
    this.router.navigate([this.config.unauthorizedDefaultRoute]);
  }
}
